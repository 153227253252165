import React from "react"
import { Content } from "./WhatDoesVPOffer"
import Hero from "./Hero"

export const WorkForUs = () => {
  return (
    <div>
      <Hero />
      <Content />
    </div>
  )
}
