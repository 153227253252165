import React from "react"
import { TitleH4, Title } from "../../../components/atoms/Title"
import { CallToActionSmall } from "../../../components/CallToAction"
import { Languages } from "../../../provider"
import { useLang } from "../../../components/Header/LanguageToggle/useLang"
import { EmailType } from "../../../utils/sendEmail"

const heroContent = {
  [Languages.EN]: {
    title: (
      <span className="leading-none tracking-normal">
        We're always looking for energized people who share our professional
        vision.
      </span>
    ),
    content: (
      <p className="mt-4 font-serif text-xl leading-tight sm:text-2xl">
        At VantagePoint, we understand the frustrations of recruitment and want
        to attract self motivated, dynamic, hardworking, team players who are
        driven to create a real impact for our trusted clients. Operating in a
        virtuous circle of excellence our clients, candidates, and our team.
        We’ve set the bar high and we want to keep it high.
      </p>
    ),
  },
  [Languages.JP]: {
    title: (
      <span className="text-3.5xl leading-tight tracking-tight sm:text-5xl">
        ビジョンに共感し、常に前進出来る方を募集しています。
      </span>
    ),
    content: (
      <p className="mt-4 text-lg tracking-tighter font-jp-serif">
        バンテージポイントは 、 「 採用はクライアントのビジネスをより良くし 、
        候補者様の人生によ り良い意味を与えるものであるべきだ 」
        というビジョンを持っています 。 私たちの仕事は 、 クライアント 、 候補者
        、 そして私たち三者の想いが重なったときに はじめ て 成立します 。 しかし
        、 それは簡単なことではありません 。 このビジョンを実現させるため 、
        クライアントと候補者に良い変化を与えたいという想いに共感し 、
        そのために自分の能力や 経験を向上させるための自発的な 努力
        を行うことができる 人材 を求めています 。
      </p>
    ),
  },
}

const content = {
  [Languages.EN]: (
    <>
      <TitleH4 title="What does VantagePoint Offer You?" />
      <p className="mt-4 sm:mt-6">
        VantagePoint is a platform where you can solve diverse recruitment
        challenges for clients and candidates, working alongside dynamic
        individuals who lead with curiosity, empathy and humility.
      </p>
      <p>
        We create the autonomy and freedom to get the job done and will surround
        you with extremely driven consultants helping you take the next step in
        your career.
      </p>
      <br />
      <p>
        If like us, you believe that work should be highly engaging, that CVs
        don’t tell the whole story, and that hard, yet efficient work and
        transparency is the only way to sustainable success, you’ve come to the
        right place.
      </p>
    </>
  ),
  [Languages.JP]: (
    <>
      <TitleH4 title="バンテージポイントでできること" />
      <p className="mt-4 sm:mt-6">
        自由な裁量が与えられることから、難しくもやりがいのある仕事に関わることができます。クライアントや候補者が抱える複雑な課題を、知識や経験、ネットワーク、そして仲間の協力を得ながら総動員で解決します。
      </p>
      <p>
        候補者のステップアップとともに、自分自身の成長も実感できるとても魅力的な仕事です。
      </p>
    </>
  ),
}

export const Content = () => {
  const lang = useLang()

  return (
    <div className="px-6 py-10 bg-white xs:p-10 sm:pt-24 sm:w-10/12 sm:mx-auto">
      <div className="max-w-4xl mt-4 mb-16 sm:mb-24">
        <Title>{heroContent[lang].title}</Title>
        <br className="h-6" />
        {heroContent[lang].content}
      </div>

      <div className="flex flex-col justify-between sm:flex-row">
        <div className="leading-snug sm:mr-12">{content[lang]}</div>
        <div className="mt-10 sm:mt-0 sm:w-1/3" style={{ minWidth: 300 }}>
          <CallToActionSmall
            title="Interested?"
            text={
              lang === Languages.EN
                ? "Enter your details and we will get in touch soon."
                : "お問合せ内容のご記入をお願いします。"
            }
            type={EmailType.career}
          />
        </div>
      </div>
    </div>
  )
}
