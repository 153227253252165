import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { PageProp } from "."
import { WorkForUs } from "../pageComponents/WorkForUs"

const CareersPage: React.FC<PageProp> = ({ location }) => {
  return (
    <Layout location={location}>
      <SEO title="Work For Us" />
      <WorkForUs />
    </Layout>
  )
}

export default CareersPage
