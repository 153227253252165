import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { useDimensions } from "../../../components/hooks/useDimensions"

const Image: React.FC<{ mobile?: boolean }> = ({ mobile }) => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "azabujuban.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <div className="absolute bottom-0 left-0 z-0 w-full h-full">
      <Img
        fluid={data.placeholderImage.childImageSharp.fluid}
        style={{ top: 0 }}
        className={mobile ? "h-full" : ""}
      />
    </div>
  )
}

const Hero = () => {
  const { width } = useDimensions()
  const isSmall = width < 1100
  return (
    <div
      className="relative flex items-end overflow-hidden sm:justify-end sm:pr-16 sm:pb-20"
      style={{
        height: "450px",
      }}
    >
      {/* <HeroText /> */}
      <Image mobile={isSmall} />
    </div>
  )
}

export default Hero
